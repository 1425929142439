<template>
    <Main>
        <b-alert v-if="apiStatus === 404" variant="danger" show>
            <div class="font-weight-bold">Fehler</div>
            Auftrag {{ rpOrderId }} nicht gefunden.
        </b-alert>
        <Box headerText="Hausanschluss" :headerInfo="'#' + order?.auftragID" :isLoading="!order">
            <template v-if="order">
                <div class="d-flex">
                    <div>{{ order.hak.tp }}</div>
                    <div class="ml-auto font-weight-bold">
                        {{ order.hak.address }}
                        <div v-if="false">{{ order.hak.postalCode }} {{ order.hak.city }}</div>
                    </div>
                </div>
                <div class="font-weight-bold">
                    <div class="d-flex mt-2">
                        Termin:
                        <span class="ml-1" v-if="isValidDate(order.executionDatePlanned)">
                            {{ order.executionDatePlanned | formatDate("DD.MM.YYYY, HH:mm") }} Uhr
                        </span>
                        <span class="ml-1" v-else>-</span>
                        <b-button v-b-toggle.collapse-termin class="ml-auto" size="sm">
                            <span><font-awesome-icon :icon="['fas', 'pencil-alt']"/></span>
                        </b-button>
                    </div>
                    <b-collapse id="collapse-termin" class="mt-2">
                        <b-card body-class="p-2">
                            <b-card-text>
                                <div>Neuer Ausführungstermin:</div>
                                <b-form-datepicker
                                    v-model="executionDateNew.date"
                                    class="mb-2"
                                    locale="de"
                                    :state="isFormValid(executionDateNew.date)"
                                />
                                <b-form-timepicker
                                    v-model="executionDateNew.time"
                                    locale="de"
                                    label-close-button="Schliessen"
                                    :state="isFormValid(executionDateNew.time)"
                                />
                                <b-button
                                    class="mt-2 d-flex ml-auto"
                                    size="sm"
                                    @click="onClickSaveExecutionDateNew"
                                    :disabled="compSaveExecutionDateIsDisabled"
                                >
                                    Speichern
                                </b-button>
                            </b-card-text>
                        </b-card>
                    </b-collapse>
                    <div class="d-flex mt-2">
                        <span class="mr-2">Bemerkung:</span>
                        <b-button
                            class="ml-auto"
                            @click="editBemerkung = !editBemerkung"
                            :disabled="editBemerkungDisabled"
                            size="sm"
                        >
                            <span><font-awesome-icon :icon="['fas', 'pencil-alt']"/></span>
                        </b-button>
                    </div>
                    <b-form-textarea
                        v-model="order.bemerkung"
                        :disabled="!editBemerkung"
                        class="mt-1"
                        max-rows="6"
                        trim
                    />
                    <b-button
                        v-if="editBemerkung"
                        class="mt-1 d-flex ml-auto"
                        @click="onClickSaveBemerkung"
                        :disabled="editBemerkungDisabled"
                        size="sm"
                    >
                        Speichern
                    </b-button>
                    <div v-b-toggle.collapse-contact class="mt-2">-> Kontakt</div>
                </div>
                <b-collapse id="collapse-contact" class="mt-2">
                    <b-alert
                        v-if="
                            !contacts ||
                                contacts.length === 0 ||
                                !contacts.some((contact) => contact.contactInfos && contact.contactInfos.length > 0)
                        "
                        class="mb-0"
                        show
                    >
                        Keine Kontakt-Infos vorhanden.
                    </b-alert>
                    <b-card v-else v-for="(contact, index) in contacts" :key="`${contact.contactID}-${index}`">
                        <b-card-text>
                            <div>
                                Name:
                                <strong>{{ contact.name === null ? contact.company : contact.name }}</strong>
                            </div>
                            <HorizontalSeparator />
                            <div
                                v-for="(ci, index) in uniqueFilteredContactInfo(contact.contactInfos)"
                                :key="`${ci.type}-${index}`"
                            >
                                <div class="d-flex">
                                    <div class="mr-2">{{ ci.type }}:</div>
                                    <div>
                                        <a v-if="ci.type === 'E-Mail'" :href="`mailto:${ci.value}`">{{ ci.value }}</a>
                                        <a v-else :href="`tel:${ci.value}`">{{ ci.value }}</a>
                                    </div>
                                </div>
                                <div v-if="ci.note && ci.note.length > 0" class="small">Anmerkung: {{ ci.note }}</div>
                                <HorizontalSeparator
                                    v-if="index < uniqueFilteredContactInfo(contact.contactInfos).length - 1"
                                />
                            </div>
                        </b-card-text>
                    </b-card>
                </b-collapse>
            </template>
        </Box>
        <Box
            v-if="compShowData && order?.realityCheckCompleted"
            headerText="Datenerfassung Hausanschluss"
            :isLoading="!order || !progressStatusHa.total"
            :variant="compStatusVariant"
        >
            <div v-if="order" class="d-flex">
                <div>
                    <!-- TODO: HAK datamodel has changed: position and positionDetails must point to new KeyValueOption field -->
                    <div>
                        Lage:
                        <template v-if="order.hak.positionKeyValueOptionText">
                            {{ order.hak.positionKeyValueOptionText }}
                        </template>
                        <template v-else>-</template>
                    </div>
                    <div>
                        Standort:
                        <template v-if="order.hak.standort">{{ order.hak.standort }}</template>
                        <template v-else>-</template>
                    </div>
                    <div>
                        Zugänglichkeit:
                        <template v-if="order.hak.positionDetailsKeyValueOptionText">
                            {{ order.hak.positionDetailsKeyValueOptionText }}
                        </template>
                        <template v-else>-</template>
                    </div>
                    <div
                        v-if="
                            progressStatusHa.total > 0 && progressStatusHa.completed.length === progressStatusHa.total
                        "
                    >
                        Daten & Gefahren erfasst
                    </div>
                </div>
                <div class="ml-auto">
                    <b-button @click="onClickHausanschluss">
                        <font-awesome-icon :icon="['fas', 'play-circle']" size="lg" />
                    </b-button>
                </div>
            </div>
            <template v-if="progressStatusHa.total" #footer>
                Status:
                <span v-if="progressStatusHa.completed.length === 0">Nicht begonnen</span>
                <span v-else-if="progressStatusHa.completed.length === progressStatusHa.total">
                    Daten & Gefahren erfasst
                </span>
                <span v-else>In Bearbeitung</span>
                ({{ progressStatusHa.completed.length }}/{{ progressStatusHa.total }})
                <div v-if="progressStatusHa.blocked.length">
                    Blockiert:
                    <span v-for="(b, i) in progressStatusHa.blocked" :key="`blocking_${i}`">
                        {{ b.stepCurrent }}{{ progressStatusHa.blocked.length - 1 !== i && ", " }}
                    </span>
                </div>
            </template>
        </Box>
        <Box
            v-if="compShowData && order?.realityCheckCompleted"
            headerText="Gerätestandort(e) / Tableau(s)"
            :isLoading="!devicepools"
            noBody
        >
            <template v-if="devicepools">
                <b-alert v-if="devicepools.length === 0" class="mx-3" show>Keine Gerätestandorte gefunden.</b-alert>
                <div v-for="(dp, index) in devicepools" :key="`dp_${dp.devicePoolID}`">
                    <ExecGsSummaryNew :dp="dp" />
                    <HorizontalSeparator v-if="index < devicepools.length - 1" />
                </div>
            </template>
        </Box>
        <REtasksVsRealityCheck
            :devicepools="devicepools"
            :is-loading-order="isLoadingOrder"
            :is-loading-devicepools="isLoadingDevicepools"
            :order="order"
            @poll-data="onRealityCheckPollData"
        />
        <b-button block @click="onClickCallcenter" :disabled="!order" class="my-3">
            <span><font-awesome-icon :icon="['fas', 'headset']" size="lg"/></span>
            Aktivität an Callcenter erstellen
        </b-button>
    </Main>
</template>

<script>
import ExecGsSummaryNew from "@/components/execution/ExecGsSummaryNew.vue";
import REtasksVsRealityCheck from "@/components/execution/checks/REtasksVsReality.vue";

import auftragDetailsAPI from "@/services/api/auftragDetails.api";
import auftragsAPI from "@/services/api/auftrags.api";
import contactsAPI from "@/services/api/contacts.api";
import devicePoolsAPI from "@/services/api/devicepools.api";

export default {
    name: "ExecutionSingleNew",
    components: { REtasksVsRealityCheck, ExecGsSummaryNew },
    data() {
        return {
            apiStatus: 0,
            contacts: [],
            devicepools: null,
            editBemerkung: false,
            editBemerkungDisabled: false,
            executionDateNew: {
                date: "",
                time: "",
            },
            isLoadingDevicepools: false,
            isLoadingOrder: false,
            order: null,
            progressStatusHa: {
                total: 0,
                in_progress: [],
                completed: [],
                blocked: [],
                todo: [],
            },
            rpOrderId: this.$route.params.orderId,
        };
    },
    computed: {
        compSaveExecutionDateIsDisabled() {
            const dateTimeActual = new Date(this.order.executionDatePlanned);
            const dateTimeNew = new Date(`${this.executionDateNew.date}T${this.executionDateNew.time}`);
            return (
                !this.isFormValid(this.executionDateNew.date) ||
                !this.isFormValid(this.executionDateNew.time) ||
                dateTimeActual.getTime() === dateTimeNew.getTime()
            );
        },
        compShowData() {
            return ["CREATED", "IN PROGRESS", "COMPLETED", "TRANSFERRING", "ERROR-TRANSFER", "TRANSFERRED"].includes(
                this.order?.auftragStatusID.toUpperCase()
            );
        },
        compStatusVariant() {
            if (this.progressStatusHa.completed.length === 0) {
                return "";
            } else if (this.progressStatusHa.blocked.length > 0) {
                return "danger";
            } else if (this.progressStatusHa.completed.length === this.progressStatusHa.total) {
                // requirement: Hausanschluss turns green when all the gs turn green (and all are completed)
                return "success";
            } else {
                return "warning";
            }
        },
    },
    mounted() {
        this.determineStatus(1).then((resp) => {
            this.progressStatusHa = resp;
        });
        this.loadOrder().then(() => {
            this.loadContacts();
            this.loadDevicePools();
        });
    },
    methods: {
        determineStatus(actionGroupId) {
            return auftragDetailsAPI
                .get({
                    auftragID: this.rpOrderId,
                    actionGroupID: actionGroupId,
                })
                .then((resp) => {
                    const stepsStatus = {
                        total: resp.length,
                        in_progress: [],
                        completed: [],
                        blocked: [],
                        todo: [],
                    };
                    resp.forEach((ad) => {
                        switch (true) {
                            case ad.status === 0:
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 100 && ad.status <= 199:
                                stepsStatus.in_progress.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            case ad.status >= 200 && ad.status <= 299:
                                stepsStatus.completed.push(ad);
                                break;
                            case ad.status >= 500 && ad.status <= 599:
                                stepsStatus.blocked.push(ad);
                                stepsStatus.todo.push(ad);
                                break;
                            default:
                                stepsStatus.todo.push(ad);
                                alert(`auftragDetail.status=${ad.status} (step ${ad.stepCurrent}) not defined!`);
                        }
                    });
                    return stepsStatus;
                });
        },
        isFormValid(value) {
            if (value) {
                let date = new Date(`1970-01-01T${value}`); // Default to time format
                if (isNaN(date.getTime())) {
                    date = new Date(value); // Try date format
                }
                return !isNaN(date.getTime());
            }
        },
        isValidDate(date) {
            return !isNaN(Date.parse(date));
        },
        loadContacts() {
            return contactsAPI.getByHakId(this.order.hakID).then((resp) => {
                this.contacts = resp;
            });
        },
        // Sort out duplicates and Telefax and Internet/WWW #20527
        uniqueFilteredContactInfo(contactInfo) {
            if (!contactInfo || contactInfo.length === 0) {
                return [];
            }
            const seenValues = new Set();
            return contactInfo
                .filter((ci) => ci.type !== "Telefax" && ci.type !== "Internet/WWW")
                .filter((ci) => {
                    if (seenValues.has(ci.value)) {
                        return false;
                    }
                    seenValues.add(ci.value);
                    return true;
                });
        },
        loadDevicePools() {
            this.isLoadingDevicepools = true;
            return devicePoolsAPI
                .get({
                    hakID: this.order.hak.hakID,
                    includeDevices: true,
                })
                .then((resp) => {
                    this.devicepools = resp;
                })
                .finally(() => {
                    this.isLoadingDevicepools = false;
                });
        },
        onClickCallcenter() {
            this.$router.push({
                name: "execution-callcenter",
                params: { orderId: this.order.auftragID.toString(), hakId: this.order.hak.hakID },
            });
        },
        onClickHausanschluss() {
            this.$router.push({ name: "step-1", params: { orderId: this.rpOrderId } });
        },
        onClickSaveBemerkung() {
            this.editBemerkungDisabled = true;
            const payload = [
                {
                    op: "replace",
                    path: "/bemerkung",
                    value: this.order.bemerkung,
                },
            ];
            auftragsAPI
                .patch(this.order.auftragID, payload)
                .then((resp) => {
                    this.order.bemerkung = resp.bemerkung;
                })
                .then(() => (this.editBemerkung = !this.editBemerkung))
                .finally(() => (this.editBemerkungDisabled = false));
        },
        onClickSaveExecutionDateNew() {
            const payload = [
                {
                    op: "replace",
                    path: "/executionDatePlanned",
                    value: `${this.executionDateNew.date}T${this.executionDateNew.time}`,
                },
            ];
            auftragsAPI.patch(this.order.auftragID, payload).then((resp) => {
                this.order.executionDatePlanned = resp.executionDatePlanned;
            });
        },
        onRealityCheckPollData() {
            this.loadData();
        },
        loadOrder() {
            this.isLoadingOrder = true;
            return auftragsAPI
                .getSingle(this.rpOrderId, { includeAsq: true })
                .then((resp) => {
                    this.apiStatus = resp.status;
                    this.order = resp.data;
                    if (this.order?.executionDatePlanned) {
                        const executionDate = new Date(this.order.executionDatePlanned);
                        if (this.isValidDate(executionDate)) {
                            // Format the date as YYYY-MM-DD
                            this.executionDateNew.date = executionDate.toLocaleDateString("en-CA");
                            // Format the time as HH:mm:ss in 24-hour format
                            this.executionDateNew.time = executionDate.toLocaleTimeString("en-CA", { hour12: false });
                        }
                    }
                })
                .catch((err) => {
                    this.apiStatus = err?.response?.status;
                })
                .finally(() => {
                    this.isLoadingOrder = false;
                });
        },
    },
};
</script>
